<template>
  <div>
    <v-card class="mx-auto elevation-0">
      <v-card-title>
        <v-toolbar
          flat
          v-bind="utilImp.cnt.CONFIG.tema.detalhe.barraSuperior.fundo"
        >
          <v-icon
            left
            v-bind="utilImp.cnt.CONFIG.tema.detalhe.barraSuperior.icone"
            >{{
              utilImp.cnt.CONFIG.tema.detalhe.barraSuperior.icone.nome
            }}</v-icon
          >
          <v-toolbar-title
            v-bind="utilImp.cnt.CONFIG.tema.detalhe.barraSuperior.titulo"
            >{{ lang("configuracao") }}</v-toolbar-title
          >
          <v-divider class="mx-2" inset horizontal></v-divider>
          <div class="flex-grow-2"></div>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-data-table
            class="elevation-0"
            height="70vh"
            fixed-header
            hide-default-footer
            v-bind:headers="titulo"
            v-bind:items="configuracao"
            v-bind:items-per-page="itemPagina"
          >
            <template v-slot:item.valor="{ item }">
              <v-text-field
                name="namValor+"
                v-model="item.valor"
                v-bind:label="lang('valor')"
                v-bind="utilImp.cnt.CONFIG.textfield"
              ></v-text-field>
            </template>
            <template v-slot:no-data>
              <v-alert v-bind:value="false" color="white" icon="info">{{
                lang("nenhumRegistroEncontrado")
              }}</v-alert>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions v-bind="utilImp.cnt.CONFIG.tema.janela.rodape">
        <v-spacer />
        <v-btn
          v-on:click="salvar()"
          v-shortkey.once="utilImp.cnt.CONFIG.btn.salvar.atalho"
          v-on:shortkey="dlgSalvar === true ? salvar() : null"
          v-bind:disabled="desabilitado"
          v-bind="utilImp.cnt.CONFIG.btn.salvar"
          ><v-icon left>{{ utilImp.cnt.CONFIG.btn.salvar.icone }}</v-icon>
          {{ lang("salvar") }}</v-btn
        >
      </v-card-actions>
    </v-card>
    <!-- Componentes -->
  </div>
</template>

<script>
import util from "../../util/Util.js";
const ITEM_PAGINA = 5000; // v-data-table

export default {
  components: {},
  mixins: [util.mixUtil, util.mixAlerta],
  data: () => ({
    utilImp: util,
    desabilitado: false,
    itemPagina: ITEM_PAGINA,
    titulo: [],
    configuracao: []
  }),
  created() {
    this.iniciar();
  },
  mounted() {
    this.exibir();
  },
  methods: {
    iniciar() {
      // Títulos da consulta
      this.titulo.push({
        text: this.lang("chave"),
        align: "left",
        value: "chave",
        sortable: true
      });
      this.titulo.push({
        text: this.lang("anotacao"),
        align: "left",
        value: "anotacao",
        sortable: true
      });
      this.titulo.push({
        text: this.lang("valor"),
        align: "left",
        value: "valor",
        sortable: true
      });
    },
    exibir() {
      this.listarRegistro();
    },
    listarRegistro() {
      this.desabilitar(true);
      this.limparLista();
      this.showLoader();
      util.srv.sistema
        .listarConfiguracaoProprietario()
        .then(response => {
          this.distribuir(response.data);
        })
        .catch(error => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.desabilitar(false);
          this.hideLoader();
        });
    },
    distribuir(arrayRegistro) {
      // Verificar se a consulta retornou dados
      if (arrayRegistro.result !== null) {
        // Verificar o status da consulta
        if (arrayRegistro.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
          this.configuracao = arrayRegistro.result;
        }
      } else {
        this.alertInfo(this.lang("nenhumRegistroEncontrado"));
      }
    },
    salvar() {
      this.validar();
    },
    // Funções que possuem await (síncrono) no seu conteúdo deve ter async no início da declaração
    async validar() {
      // if (!await this.$validator.validate('namUnidade')) {
      //   this.alertWarning(this.errors.first('namUnidade'))
      //   return
      // }
      this.alterar();
    },
    alterar() {
      this.desabilitar(true);
      util.srv.sistema
        .alterarConfiguracaoProprietario(this.configuracao)
        .then(response => {
          if (response.data.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
            this.limparLista();
            this.listarRegistro();
            this.alertSucessToast(this.lang("sucesso"));
          } else {
            this.alertWarning(this.langResultCode(response.data.resultCode));
          }
        })
        .catch(error => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.desabilitar(false);
        });
    },
    desabilitar(desabilitado) {
      this.desabilitado = desabilitado;
    },
    limpar() {
      this.limparLista();
    },
    limparLista() {
      this.configuracao = [];
    }
  }
};
</script>
